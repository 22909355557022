<template>
  <div
    class="
      edit-create-store-application-form
      d-flex
      flex-column
      align-center
      mx-4
    "
  >
    <div class="text-h4 mt-16 mb-8">
      {{ $t("__edit_create_store_application_form_title") }}
    </div>
    <v-card class="ma-2" elevation="0" max-width="600" width="100%">
      <v-card-text class="pa-6">
        <CreateStoreApplicationForm
          ref="form"
          :form="form"
          @update:form="form = $event"
          :valid="valid"
          @update:valid="valid = $event"
        />
        <div class="caption mb-2 error--text">{{ error }}</div>
        <div class="d-flex">
          <v-btn outlined color="light-blue" @click="toSaveForm">
            {{ $t("__save") }}
          </v-btn>
          <v-btn
            class="mr-3 ml-auto"
            outlined
            color="success"
            @click="toUploadPhoto"
            :disabled="!valid"
          >
            {{ $t("__upload_photo") }}
          </v-btn>
          <v-btn
            :disabled="!photosIsUploaded"
            color="success"
            @click="toSubmitForm"
          >
            {{ $t("__submit") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CreateStoreApplicationForm from "@/components/CreateStoreApplicationForm";
import { mapGetters } from "vuex";

export default {
  name: "EditCreateStoreApplicationForm",
  components: {
    CreateStoreApplicationForm
  },
  computed: {
    ...mapGetters({
      createdForm: "createStoreApplicationForm/createStoreApplicationForm"
    })
  },
  data() {
    return {
      form: {
        store_name: "",
        store_industry_type: "retail",
        owner_name: "",
        owner_phone_number: "",
        owner_email: "",
        store_owner_type: "company",
        tax_id_number: "",
        store_phone_number: "",
        store_address_detail: {
          postal_code: "000",
          city: "",
          area: "",
          road: "",
          lane: "",
          alley: "",
          number: "",
          sub_number: "",
          floor: "",
          room: ""
        },
        store_bank_code: "",
        store_bank_account: "",
        store_website: "",
        telegram_bot_token: ""
      },
      photoFields: [
        {
          name: "bank_passbook",
          quantity: 1,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "location_with_owner",
          quantity: 3,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "environment_with_owner",
          quantity: 3,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "registration_certificate",
          quantity: 1,
          photoURLs: [],
          storeOwnerTypes: ["company"]
        },
        {
          name: "owner_id_card",
          quantity: 2,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "owner_id_card_with_owner",
          quantity: 2,
          photoURLs: [],
          storeOwnerTypes: ["personal"]
        }
      ],
      valid: false,
      error: "",
      photosIsUploaded: false,
      completed: false,
      rejected: false
    };
  },
  methods: {
    loadCreateStoreApplicationForm() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch(
          "createStoreApplicationForm/fetchCreateStoreApplicationForm",
          {
            createStoreApplicationFormID: this.$route.params.id
          }
        )
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.replace({ name: "CreateStoreApplicationForms" });
        });
    },
    addCreateForm() {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("createStoreApplicationForm/addCreateStoreApplicationForm", {
          formData: this.form
        })
        .then(createStoreApplicationFormID => {
          this.$store.dispatch("setIsLoading", false);
          if (this.completed) {
            this.$router.replace({
              name: "UploadApplyStorePhoto",
              params: { id: createStoreApplicationFormID }
            });
          } else {
            this.$router.push({
              name: "CreateStoreApplicationForms"
            });
          }
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          this.error = err.message;
        });
    },
    editCreateStoreApplicationForm() {
      this.$refs.form.validate();
      delete this.form["store_address"];
      if (this.valid) {
        this.$store.dispatch("setIsLoading", true);
        this.$store
          .dispatch(
            "createStoreApplicationForm/setCreateStoreApplicationForm",
            {
              setData: this.form
            }
          )
          .then(() => {
            this.$store.dispatch("setIsLoading", false);
            if (this.completed) {
              this.$router.push({
                name: "UploadApplyStorePhoto",
                params: { id: this.$route.params.id }
              });
            } else {
              this.$router.replace({ name: "CreateStoreApplicationForms" });
            }
          })
          .catch(err => {
            this.$store.dispatch("setIsLoading", false);
            this.error = err.message;
          });
      }
    },
    checkPhotosIsUploaded(form) {
      let isUploaded = true;
      if (!!form && !!form["photo_urls"]) {
        isUploaded =
          isUploaded &&
          !!form["photo_urls"]["bank_passbook"] &&
          form["photo_urls"]["bank_passbook"].length >= 1 &&
          !!form["photo_urls"]["location_with_owner"] &&
          form["photo_urls"]["location_with_owner"].length >= 3 &&
          !!form["photo_urls"]["environment_with_owner"] &&
          form["photo_urls"]["environment_with_owner"].length >= 3;
        if (form["store_owner_type"] === "company") {
          isUploaded =
            isUploaded &&
            !!form["photo_urls"]["registration_certificate"] &&
            form["photo_urls"]["registration_certificate"].length >= 1 &&
            !!form["photo_urls"]["owner_id_card"] &&
            form["photo_urls"]["owner_id_card"].length >= 2;
        } else if (form["store_owner_type"] === "personal") {
          isUploaded =
            isUploaded &&
            !!form["photo_urls"]["owner_id_card"] &&
            form["photo_urls"]["owner_id_card"].length >= 2 &&
            !!form["photo_urls"]["owner_id_card_with_owner"] &&
            form["photo_urls"]["owner_id_card_with_owner"].length >= 2;
        } else {
          isUploaded = false;
        }
      } else {
        isUploaded = false;
      }
      return isUploaded;
    },
    toUploadPhoto() {
      this.completed = true;
      if (!this.form["store_address_detail"]["sub_number"]) {
        this.form["store_address_detail"]["sub_number"] = 0;
      }
      if (this.rejected) {
        this.addCreateForm();
      } else {
        this.editCreateStoreApplicationForm();
      }
    },
    toSubmitForm() {
      if (!this.form["store_address_detail"]["sub_number"]) {
        this.form["store_address_detail"]["sub_number"] = 0;
      }
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("createStoreApplicationForm/submitCreateStoreApplicationForm")
        .then(() => {
          this.loadCreateStoreApplicationForm();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.error("err", err);
          alert(err.message);
        });
    },
    toSaveForm() {
      this.completed = false;
      if (!this.form["store_address_detail"]["sub_number"]) {
        this.form["store_address_detail"]["sub_number"] = 0;
      }
      if (this.rejected) {
        this.addCreateForm();
      } else {
        this.editCreateStoreApplicationForm();
      }
    }
  },
  watch: {
    createdForm: {
      immediate: true,
      handler(val) {
        // if form status not unsubmitted and not rejected go back
        if (
          !!val &&
          val["status"] !== "unsubmitted" &&
          val["status"] !== "rejected"
        ) {
          this.$router.replace({ name: "CreateStoreApplicationForms" });
        }
        if (!!val && val["status"] === "rejected") {
          this.rejected = true;
        }
        // text value
        this.form = {
          store_name: val ? val["store_name"] : "",
          store_industry_type: val ? val["store_industry_type"] : "",
          owner_name: val ? val["owner_name"] : "",
          owner_phone_number: val ? val["owner_phone_number"] : "",
          owner_email: val ? val["owner_email"] : "",
          store_owner_type: val ? val["store_owner_type"] : "company",
          tax_id_number: val ? val["tax_id_number"] : "",
          store_phone_number: val ? val["store_phone_number"] : "",
          store_address: val ? val["store_address"] : "",
          store_address_detail: val ? val["store_address_detail"] : {},
          store_bank_code: val ? val["store_bank_code"] : "",
          store_bank_account: val ? val["store_bank_account"] : "",
          store_website: val ? val["store_website"] : ""
        };
        if (this.form["store_address_detail"]["sub_number"] === 0) {
          this.form["store_address_detail"]["sub_number"] = "";
        }
        //check photo is uploaded
        for (const i in this.photoFields) {
          const photoName = this.photoFields[i].name;
          this.photoFields[i].photoURLs = [];
          if (
            !!val &&
            !!val["photo_urls"] &&
            !!val["photo_urls"][photoName] &&
            val["photo_urls"][photoName].length > 0
          ) {
            for (const photoURL of val["photo_urls"][photoName]) {
              this.photoFields[i].photoURLs.push(photoURL);
            }
          }
        }
        this.photosIsUploaded = this.checkPhotosIsUploaded(val);
      }
    }
  },
  created() {
    this.loadCreateStoreApplicationForm();
  }
};
</script>
